// lib
import React from 'react';
import { Route } from 'react-router-dom';

// components
import Account from '../Account/Account';
import Dashboard from '../Dashboard/Dashboard';
import Contact from '../../Contact/Contact';
import EcoWallet from '../EcoWallet/EcoWallet';
import EcoSupplies from '../EcoSupplies/EcoSupplies';
import Checkout from '../EcoSupplies/Checkout';
import Metrics from '../Metrics/Metrics';
import FAQ from '../FAQ/FAQ';
import Schedule from '../Schedule/Schedule';
import HowTo from '../HowTo/HowTo';
import Recyclable from '../Recyclable/Recyclable';
import Locations from '../Locations/Locations';

export default function Content() {
  return (
    <>
      <Route path="/portal/schedule" component={Schedule} />
      <Route path="/portal/metrics" component={Metrics} />
      <Route path="/portal/dashboard" component={Dashboard} />
      <Route path="/portal/ecowallet" component={EcoWallet} />
      {/* <Route path="/portal/ecosupplies" component={EcoSupplies} /> */}
      <Route path="/portal/recyclable" component={Recyclable} />
      <Route path="/portal/locations" component={Locations} />
      <Route path="/portal/account" component={Account} />
      <Route path="/portal/howto" component={HowTo} />
      <Route path="/portal/faq" component={FAQ} />
      <Route path="/portal/checkout" component={Checkout} />
      <Route path="/portal/contact" component={Contact} />
    </>
  );
}
