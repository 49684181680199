import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import faqStyle from 'assets/jss/web-app/faqStyle';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(faqStyle);

const DashboardLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink exact to="/portal/dashboard" className={classes.link}>
      {t('common.dashboard')}
    </NavLink>
  );
};

const SchedulerLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink exact to="/portal/scheduler" className={classes.link}>
      {t('common.scheduler')}
    </NavLink>
  );
};

const QuickDropLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink exact to="/portal/locations" className={classes.link}>
      {t('faq.whatIsEcoSack.p1.quickDropLink')}
    </NavLink>
  );
};

const EcoSuppliesLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink exact to="/portal/ecosupplies" className={classes.link}>
      {t('common.ecoSupplies')}
    </NavLink>
  );
};

const WhatCanRecycleLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink exact to="/portal/recyclable" className={classes.link}>
      {t('common.whatCanRecycle')}
    </NavLink>
  );
};

export const Faq1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography className={classes.smallMargin}>
          {t('faq.howOftenPickup.p1')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howOftenPickup.p2')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t('faq.howOftenPickup.p3')} <DashboardLink />.
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography className={classes.smallMargin}>
          {t('faq.whatTimeBulkPickup.p1')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatTimeBulkPickup.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq3 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography className={classes.smallMargin}>
          {t('faq.whatTimeCurbsidePickup.p1')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatTimeCurbsidePickup.p2')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatTimeCurbsidePickup.p3')}</Typography>
      </Box>
    </React.Fragment>
  );
};

// link
export const Faq4 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.howSchedulePickup.p1.headToThe')} <SchedulerLink />{' '}
          {t('faq.howSchedulePickup.p1.tab')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

// ul
export const Faq5 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whereToPutEcoSacksEcoKrates.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ul>
            <li>{t('faq.whereToPutEcoSacksEcoKrates.list.p1')}</li>
            <li>{t('faq.whereToPutEcoSacksEcoKrates.list.p2')}</li>
            <li>{t('faq.whereToPutEcoSacksEcoKrates.list.p3')}</li>
            <li>{t('faq.whereToPutEcoSacksEcoKrates.list.p4')}</li>
            <li>{t('faq.whereToPutEcoSacksEcoKrates.list.p5')}</li>
          </ul>
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whereToPutEcoSacksEcoKrates.p2')} </Typography>
      </Box>
    </React.Fragment>
  );
};

// TO DO - NEED TO CONFIRM WHAT THIS SHOULD SAY
export const Faq6 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.howConfirmPickup.question')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ol>
            <li>
              {t('faq.howConfirmPickup.list.onYourPortal')} <DashboardLink />
              <br></br>
              {t('faq.howConfirmPickup.list.canBeDoneTime')}
            </li>
            <li>
              <Trans>{t('faq.howConfirmPickup.list.continued')}</Trans>
            </li>
          </ol>
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howConfirmPickup.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq7 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.canCancelPickup.p1.absolutely')} <DashboardLink />{' '}
          {t('faq.canCancelPickup.p1.anytime')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.canCancelPickup.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq8 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.canAutoConfirmPickup.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t('faq.canAutoConfirmPickup.p2.absolutely')} <DashboardLink />
          {t('faq.canAutoConfirmPickup.p2.remember')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq9 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whatToDoMissedPickup.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatToDoMissedPickup.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq10 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.whatIsEcoSack.p1.mainText')} <QuickDropLink />
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatIsEcoSack.p2')}</Typography>
      </Box>
      {/* <Box mb={2}>
        <Typography>
          {t('faq.whatIsEcoSack.p3.needMore')} <EcoSuppliesLink />{' '}
          {t('faq.whatIsEcoSack.p3.section')}
        </Typography>
      </Box> */}
    </React.Fragment>
  );
};
export const Faq11 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whatIsEcoKrate.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatIsEcoKrate.p2')}</Typography>
      </Box>
      {/* <Box mb={2}>
        <Typography>
          {t('faq.whatIsEcoKrate.p3.needMore')} <EcoSuppliesLink />{' '}
          {t('faq.whatIsEcoKrate.p3.section')}
        </Typography>
      </Box> */}
    </React.Fragment>
  );
};
export const Faq12 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.howMoreEcoSacksAndEcoKrates.p1.needMore')} <EcoSuppliesLink />{' '}
          {t('faq.howMoreEcoSacksAndEcoKrates.p1.section')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq14 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.howEcoSuppliesDelivered.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howEcoSuppliesDelivered.p2')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howEcoSuppliesDelivered.p3')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq16 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.whatToDoIncorrectEcoSuppliesOrder.p1')}{' '}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatToDoIncorrectEcoSuppliesOrder.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq17 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whatToDoDamagedStolenGoods.p1')} </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.whatToDoDamagedStolenGoods.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq18 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.howManyItemsEcoSack.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howManyItemsEcoSack.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq19 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.differenceCurbsideBulk.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.differenceCurbsideBulk.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};

// TODO: needs updated text, answer does not match question
export const Faq20 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.howToChangePickupLocation.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howToChangePickupLocation.p2')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howToChangePickupLocation.p3')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ol>
            <li>{t('faq.howToChangePickupLocation.list.p1')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p2')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p3')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p4')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p5')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p6')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p7')}</li>
            <li>{t('faq.howToChangePickupLocation.list.p8')}</li>
          </ol>
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export const Faq21 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.willPickupFromHomeAndBusiness.p1')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq22 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.howMuchPaid.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.howMuchPaid.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq23 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.whatItemsCanRecycle.p1.currentlyOnly')} <WhatCanRecycleLink />{' '}
          {t('faq.whatItemsCanRecycle.p1.learnMore')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq24 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whatNotRecyclable.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t('faq.whatNotRecyclable.p2.pleaseVisit')} <WhatCanRecycleLink />{' '}
          {t('faq.whatNotRecyclable.p2.toLearnMore')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq25 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.doNeedToSort.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{t('faq.doNeedToSort.p2')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq26 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>
          {t('faq.howRecyclingReduceCarbon.p1.eachContainer')}{' '}
          <NavLink exact to="/portal/metrics" className={classes.link}>
            {t('common.metrics')}
          </NavLink>{' '}
          {t('faq.howRecyclingReduceCarbon.p1.tabOfDashboard')}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq27 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.whoToContactMoreHelp.p1')}</Typography>
      </Box>
    </React.Fragment>
  );
};
export const Faq28 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography>{t('faq.lostEcoCard.p1')}</Typography>
      </Box>
    </React.Fragment>
  );
};

export const FaqNY1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Typography>{t('faq.whatIsNYQualifying.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ul>
            <li>{t('faq.whatIsNYQualifying.list.1')}</li>
            <li>{t('faq.whatIsNYQualifying.list.2')}</li>
            <li>{t('faq.whatIsNYQualifying.list.3')}</li>
            <li>{t('faq.whatIsNYQualifying.list.4')}</li>
            <li>
              {t('faq.whatIsNYQualifying.list.5')}{' '}
              <a
                className={classes.link}
                href="https://www.dec.ny.gov/chemical/8833.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                dec.ny.gov
              </a>
            </li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export const FaqCA1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Typography>{t('faq.whatIsCACRV.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ul>
            <li>{t('faq.whatIsCACRV.list.1')}</li>
            <li>{t('faq.whatIsCACRV.list.2')}</li>
            <li>{t('faq.whatIsCACRV.list.3')}</li>
            <li>{t('faq.whatIsCACRV.list.4')}</li>
            <li>{t('faq.whatIsCACRV.list.5')}</li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export const FaqCA2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Typography>{t('faq.doCARedemptionAmountsVary.p1')}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <ul>
            <li>{t('faq.doCARedemptionAmountsVary.list.1')}</li>
            <li>{t('faq.doCARedemptionAmountsVary.list.2')}</li>
            <li>{t('faq.doCARedemptionAmountsVary.list.3')}</li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export const faqs = [
  /*{
    question: 'faq.howOftenPickup.question',
    answer: <Faq1 />,
    service: 'curbside',
  },
  {
    question: 'faq.whatTimeBulkPickup.question',
    answer: <Faq2 />,
    service: 'curbside',
  },
  {
    question: 'faq.whatTimeCurbsidePickup.question',
    answer: <Faq3 />,
    service: 'curbside',
  },
  {
    question: 'faq.howSchedulePickup.question',
    answer: <Faq4 />,
    service: 'curbside',
  },
  {
    question: 'faq.whereToPutEcoSacksEcoKrates.question',
    answer: <Faq5 />,
    service: 'curbside',
  },
  {
    question: 'faq.howConfirmPickup.question',
    answer: <Faq6 />,
    service: 'curbside',
  },
  {
    question: 'faq.canCancelPickup.question',
    answer: <Faq7 />,
    service: 'curbside',
  },
  {
    question: 'faq.canAutoConfirmPickup.question',
    answer: <Faq8 />,
    service: 'curbside',
  },
  {
    question: 'faq.whatToDoMissedPickup.question',
    answer: <Faq9 />,
    service: 'curbside',
  },*/
  {
    question: 'faq.whatIsEcoSack.question',
    answer: <Faq10 />,
    service: 'all',
  },
  {
    question: 'faq.whatIsEcoKrate.question',
    answer: <Faq11 />,
    service: 'all',
  },
  ,
  // {
  //   question: 'faq.howMoreEcoSacksAndEcoKrates.question',
  //   answer: <Faq12 />,
  //   service: 'all',
  // }
  /*
  {
    question: 'faq.howEcoSuppliesDelivered.question',
    answer: <Faq14 />,
    service: 'all',
  },
  {
    question: 'faq.whatToDoIncorrectEcoSuppliesOrder.question',
    answer: <Faq16 />,
    service: 'all',
  },*/ {
    question: 'faq.whatToDoDamagedStolenGoods.question',
    answer: <Faq17 />,
    service: 'all',
  },
  {
    question: 'faq.howManyItemsEcoSack.question',
    answer: <Faq18 />,
    service: 'all',
  },
  /*{
    question: 'faq.differenceCurbsideBulk.question',
    answer: <Faq19 />,
    service: 'curbside',
  },
  {
    question: 'faq.howToChangePickupLocation.question',
    answer: <Faq20 />,
    service: 'curbside',
  },
  {
    question: 'faq.willPickupFromHomeAndBusiness.question',
    answer: <Faq21 />,
    service: 'curbside',
  },
  {
    question: 'faq.howMuchPaid.question',
    answer: <Faq22 />,
    service: 'all',
  },*/
  {
    question: 'faq.whatItemsCanRecycle.question',
    answer: <Faq23 />,
    service: 'all',
  },
  {
    question: 'faq.whatNotRecyclable.question',
    answer: <Faq24 />,
    service: 'all',
  },
  {
    question: 'faq.doNeedToSort.question',
    answer: <Faq25 />,
    service: 'all',
  },
  {
    question: 'faq.howRecyclingReduceCarbon.question',
    answer: <Faq26 />,
    service: 'all',
  },
  {
    question: 'faq.whoToContactMoreHelp.question',
    answer: <Faq27 />,
    service: 'all',
  },
  {
    question: 'faq.lostEcoCard.question',
    answer: <Faq28 />,
    service: 'all',
  },
];

// FAQs specific to a state, or it has text that is targeted towards users of that state
export const stateBasedFAQs = {
  NY: [
    {
      question: 'faq.whatIsNYQualifying.question',
      answer: <FaqNY1 />,
      service: 'all',
    },
  ],
  CA: [
    {
      question: 'faq.whatIsCACRV.question',
      answer: <FaqCA1 />,
      service: 'all',
    },
    {
      question: 'faq.doCARedemptionAmountsVary.question',
      answer: <FaqCA2 />,
      service: 'all',
    },
  ],
};
