import { SET_BLOG_POSTS } from './types.js';
import axios from 'axios';
import { SERVER_URL } from '../../config.js';
import { articleLinks } from 'lib/articleLinks';

const returnDefaultConfig = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return config;
};

export const getBlogPosts = () => (dispatch) => {
  const config = returnDefaultConfig();

  axios
    .get(`${SERVER_URL}/blog/posts`, config)
    .then((response) => {
      console.log('response.data:', response.data);
      dispatch({
        type: SET_BLOG_POSTS,
        payload: articleLinks.concat(response.data.reverse()),
      });
    })
    .catch((err) => {
      console.error(`Error loading blog posts: ${err}`);
    });
};
