import vcStarLogo from 'assets/img/article-img-vc-star.png';

export const articleLinks = [
  {
    id: 'article-link-01',
    article: true,
    link: 'https://www.vcstar.com/story/news/local/2024/11/10/eco-tip-new-technology-could-revive-buyback-recycling/76159454007/',
    title: 'Eco-tip: New technology could revive buyback recycling',
    alt: 'New Eco Tech',
    author_first_name: 'David',
    author_last_name: 'Goldstein',
    date: '2024-11-10',
    header_img: vcStarLogo,
    description:
      'An unexpected problem arose on California’s way to a better recycling future.',
    body: '',
  },
];
