import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Parallax } from 'react-parallax';
import { useTranslation } from 'react-i18next';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, Container, Box } from '@material-ui/core';

// components
import BlogCard from 'components/BlogCard/BlogCard';
import Page from 'components/Page/Page';
import { getBlogPosts } from 'redux/actions/blog';
import Section from 'components/Page/Section';
import CoFounder from 'views/About/CoFounder';
import Employee from 'views/About/Employee';
import RecycletekTimeline from 'views/About/RecycletekTimeline';

// assets
import world from 'assets/img/world.png';
import martin from 'assets/img/martin.jpg';
import yoni from 'assets/img/yoni.jpg';
import xavier from 'assets/img/xavier.png';
import tobi from 'assets/img/tobias_weis.jpg';
import megan from 'assets/img/megan.png';
import matt from 'assets/img/mattShapiro.png';
import jackie from 'assets/img/jackieEbner.png';
import jeffery from 'assets/img/jefferyASmith.jpg';
import matthew from 'assets/img/matthewFord.png';
import greg from 'assets/img/gregoryLanz.png';
import zwf from 'assets/img/recycletek-facility-bg.png';

const useStyles = makeStyles((theme) => ({
  headerImage: {
    padding: '0',
    margin: '0',
    right: '0',
    position: 'absolute',
    zIndex: '-1',
    maskImage: 'linear-gradient(black, transparent)',
    '& img': {
      width: '100%',
      maxWidth: '423px',
      maxHeight: '850px',
      [theme.breakpoints.down('sm')]: {
        opacity: '0.4',
        maxWidth: '380px',
      },
      [theme.breakpoints.down('xs')]: {
        opacity: '0.2',
        maxWidth: '320px',
      },
    },
  },
  posts: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  postsLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  parallax: {
    width: '100%',
    height: '600px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const About = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog.posts);
  const { t } = useTranslation();

  useEffect(() => {
    !posts && dispatch(getBlogPosts());
  }, []);

  return (
    <Page>
      <Container>
        <Box className={classes.headerImage}>
          <img src={world} alt="Recycletek Green World" />
        </Box>

        <Section text noMarginTop>
          <Typography variant="h2">{t('about.ourMissionTitle')}</Typography>
          <Typography variant="body2">{t('about.mainGoalMessage')}</Typography>
        </Section>
        <Section>
          <Typography variant="h3">{t('about.news')}</Typography>
          <Box className={classes.posts}>
            {posts &&
              posts
                .slice(0, 4)
                .map((post) => <BlogCard key={post.id} post={post} />)}
            {!posts && (
              <Typography variant="body1">{t('about.noPostsFound')}</Typography>
            )}
          </Box>
          <Box className={classes.postsLink}>
            <NavLink to="/blogs">
              <i class="fa fa-newspaper"></i> {t('about.viewAllPosts')}
            </NavLink>
          </Box>
        </Section>
      </Container>
      <Parallax
        blur={0}
        strength={1000}
        bgImage={zwf}
        className={classes.parallax}
      ></Parallax>
      <Container>
        <Section>
          <Typography variant="h3">{t('about.coFoundersTitle')}</Typography>
          <CoFounder
            name={t('about.martinName')}
            position={t('about.martinPosition')}
            description={t('about.martinDescription')}
            image={martin}
          />
          <CoFounder
            name={t('about.yoniName')}
            position={t('about.yoniPosition')}
            description={t('about.yoniDescription')}
            image={yoni}
          />
          <CoFounder
            name={t('about.tobiName')}
            position={t('about.tobiPosition')}
            description={t('about.tobiDescription')}
            image={tobi}
          />
        </Section>
        <Section>
          <Section text noMarginBottom>
            <Typography variant="h3">
              {t('about.recycletekTeamTitle')}
            </Typography>
            <Typography variant="body2">
              {t('about.recycletekTeamBody')}
            </Typography>
          </Section>
          <Grid container justify="center">
            <Employee
              name={t('about.xavierName')}
              position={t('about.xavierPosition')}
              image={xavier}
            />
            <Employee
              name={t('about.meganName')}
              position={t('about.meganPosition')}
              image={megan}
            />
            <Employee
              name={t('about.mattName')}
              position={t('about.mattPosition')}
              image={matt}
            />
            <Employee
              name={t('about.jackieName')}
              position={t('about.jackiePosition')}
              image={jackie}
            />
            <Employee
              name={t('about.jeffName')}
              position={t('about.jeffPosition')}
              image={jeffery}
            />
            <Employee
              name={t('about.matthewName')}
              position={t('about.matthewPosition')}
              image={matthew}
            />
            <Employee
              name={t('about.gregoryName')}
              position={t('about.gregoryPosition')}
              image={greg}
            />
          </Grid>
        </Section>

        <Section>
          <Typography variant="h3">
            {t('about.recycletekMilestones')}
          </Typography>
          <RecycletekTimeline />
        </Section>
      </Container>
    </Page>
  );
};

export default About;
