import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import BasicCard from 'components/Card/BasicCard';
// assets
import logo from 'assets/img/logo/logoOnly.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#007a9c',
    boxShadow: theme.shadows[5],
  },
  headerTitle: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: '700px',
    fontWeight: 300,
    margin: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: 400,
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
      background: 'inherit',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3),
    width: 'max-content',
  },
  logo: {
    width: '3.5rem',
    height: 'auto',
    padding: '1px',
    filter: 'drop-shadow(2px 2px 3px rgb(0 0 0 / 0.4))',
  },
}));

function AnnouncementCard(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const Link = ({ path, text }) => {
    return (
      <>
        <NavLink exact to={path} className={classes.link}>
          {t(text)}
        </NavLink>{' '}
      </>
    );
  };

  const Header = () => {
    return (
      <Box display="flex" alignItems="center" pl={2} pt={2} pr={2} pb={0}>
        <img className={classes.logo} src={logo} alt="logo" />
        <Typography variant="h4" className={classes.headerTitle}>
          {t('dashboard.prompt.recyclingReimagined')}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <BasicCard backgroundColor={'#007a9c'}>
        <Header />
        <Typography className={classes.text}>
          {t('dashboard.prompt.pointOne.collectAndSort')}{' '}
          <Link
            path="/portal/recyclable"
            text="dashboard.prompt.links.containers"
          />
          {t('dashboard.prompt.pointOne.in')}{' '}
          <Link
            path="/portal/faq"
            text="dashboard.prompt.links.trackableEcoSacks"
          />
          {t('dashboard.prompt.pointOne.orStandardBags')}
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.prompt.pointTwo.visitALocation')}{' '}
          <Link
            path="/portal/locations"
            text="dashboard.prompt.links.recycletekLocation"
          />
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.prompt.pointThree.recycletekWillProcess')}{' '}
          <Link
            path="/portal/howto"
            text="dashboard.prompt.links.ecoWalletOrEcoCard"
          />
          {t('dashboard.prompt.pointThree.prepaidMasterCardOr')}
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.prompt.pointFour.dropOffYour')}{' '}
          <Link
            path="/portal/ecosupplies"
            text="dashboard.prompt.links.ecoSacks"
          />
          {t('dashboard.prompt.pointFour.toReceivePayment')}
        </Typography>
        <Typography className={classes.text}>
          {t('dashboard.prompt.pointFive.trackYour')}{' '}
          <Link
            path="/portal/metrics"
            text="dashboard.prompt.links.recycletekMetrics"
          />
        </Typography>
        <Typography className={classes.list}>
          <Link path="/portal/howto" text="dashboard.prompt.links.howItWorks" />
          <Link
            path="/portal/locations"
            text="dashboard.prompt.links.recycletekLocations"
          />
          <Link
            path="/portal/recyclable"
            text="dashboard.prompt.links.whatCanYouRecycle"
          />
          <Link
            path="/portal/faq"
            text="dashboard.prompt.links.whatIsAnEcoSack"
          />
          <Link
            path="/portal/ecosupplies"
            text="dashboard.prompt.links.orderEcoSupplies"
          />
        </Typography>
      </BasicCard>
    </>
  );
}

export default AnnouncementCard;

AnnouncementCard.propTypes = {
  children: PropTypes.node,
};
